@import url("https://fonts.googleapis.com/css?family=Lato:300,400,500");

@font-face {
  font-family: "Balloon";
  src: url("/assets/fonts/balloon_friends.ttf") format("truetype");
}

$font-family-default: "Lato", sans-serif;
$font-weight-default: 300;
$font-weight-heavy: 400;

html {
  $color: black;
  $color-background: white;
  $color-accent: black;
  $color-code: #990000;
  $color-code-background: darken($color-background, 6%);

  --color: #{$color};
  --color-accent: #{$color-accent};
  --color-background: #{$color-background};
  --color-code: #{$color-code};
  --color-code-background: #{$color-code-background};
}

html[data-theme="dark"] {
  $color: #F7F7F7;
  $color-background: #121212;
  $color-accent: #2d8195;
  $color-code: $color-accent;
  $color-code-background: lighten($color-background, 10%);

  --color: #{$color};
  --color-accent: #{$color-accent};
  --color-background: #{$color-background};
  --color-code: #{$color-code};
  --color-code-background: #{$color-code-background};
}

h1 {
  font-weight: $font-weight-default;
}

h2, h3, h4, h5, h6 {
  font-weight: $font-weight-heavy;
}

h1, h2, h3, h4, h5, h6, div.item {
  color: var(--color);

  a {
    color: inherit;
  }
}

.font-weight-default {
  font-weight: $font-weight-default;
}

.font-weight-heady {
  font-weight: $font-weight-heavy;
}

strong {
  font-weight: $font-weight-heavy;
}

a {
  font-weight: $font-weight-heavy;
  text-decoration: none;
  color: var(--color-accent);
}

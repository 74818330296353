@import "theme";

body {
  font-family: $font-family-default;
  font-weight: $font-weight-default;
  max-width: 800px;
  height: 100vh;
  margin: 0 auto;
  user-select: none;
  background-color: var(--color-background);
}

.container {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: transparent;

  #splash {
    #header {
      font-size: 48px;
      margin: 0;
      color: var(--color);

      #logo {
        display: inline-block;
        vertical-align: bottom;
        cursor: pointer;
      }
    }

    #links {
      margin-top: 1em;
      margin-left: 1px;

      span {
        font-size: 24px;
        padding-right: 1em;

        a {
          font-weight: $font-weight-default;
          color: var(--color);
        }

        &.theme-toggle {
          color: var(--color);
          cursor: pointer;
        }
      }
    }
  }
}

#canvas {
  z-index: -1;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

#control {
  position: absolute;
  top: 0;
  right: 0;
  padding: 5px 10px;
  cursor: pointer;
}

